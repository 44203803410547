<div class="notification-box" (click)="toggleNotificationMobile()">
  <app-feather-icons [icon]="'bell'"></app-feather-icons>
  <span class="badge badge-pill badge-primary">4</span>
</div>
<ul class="notification-dropdown onhover-show-div" [class.active]="openNotification">
  <li>
    <app-feather-icons [icon]="'bell'"></app-feather-icons>
    <h6 class="f-18 mb-0">Notificações</h6>
  </li>
  <li>
    <p>
      <i class="fa fa-circle-o mr-3 font-primary"></i>Processamento de entrega <span class="pull-right">10 min</span>
    </p>
  </li>
  <li>
    <p>
      <i class="fa fa-circle-o mr-3 font-success"></i>Pedido completo<span class="pull-right">1 h</span>
    </p>
  </li>
  <li>
    <p>
      <i class="fa fa-circle-o mr-3 font-info"></i>Tickets gerados<span class="pull-right">3 h</span>
    </p>
  </li>
  <li>
    <p>
      <i class="fa fa-circle-o mr-3 font-danger"></i>Entrega completa<span class="pull-right">6 h</span>
    </p>
  </li>
  <li>
    <a class="btn btn-primary" href="#">Ver todas</a>
  </li>
</ul>