<app-feather-icons [icon]="'message-square'" (click)="toggleMessageBox()"></app-feather-icons>
<ul class="chat-dropdown onhover-show-div" [class.active]="openMessageBox">
  <li>
    <app-feather-icons [icon]="'message-square'"></app-feather-icons>
    <h6 class="f-18 mb-0">Mensagens</h6>
  </li>
  <li>
    <div class="media"><img class="img-fluid rounded-circle mr-3" src="assets/images/user/1.jpg" alt="">
      <div class="status-circle online"></div>
      <div class="media-body"><span>Teresa</span>
        <p>
          Lorem, ipsum dolor sit amet consectetur!
        </p>
        <p class="f-8 font-primary mb-0">3 horas atr&aacute;s</p>
      </div>
    </div>
  </li>
  <li>
    <div class="media"><img class="img-fluid rounded-circle mr-3" src="assets/images/user/2.jpg" alt="">
      <div class="status-circle online"></div>
      <div class="media-body"><span>Alan</span>
        <p>
          Gonsectetur, id architecto a perferendis.
        </p>
        <p class="f-8 font-primary mb-0">4 horas atr&aacute;s</p>
      </div>
    </div>
  </li>
  <li>
    <div class="media"><img class="img-fluid rounded-circle mr-3" src="assets/images/user/4.jpg" alt="">
      <div class="status-circle offline"></div>
      <div class="media-body"><span>Juliana</span>
        <p>
          Lelectus deserunt architecto voluptatum.
        </p>
        <p class="f-8 font-primary mb-0">1 dia atrás</p>
      </div>
    </div>
  </li>
  <li class="text-center"> <a class="btn btn-primary" href="#">Ver todos</a></li>
</ul>